import * as React from "react";
import moment from "moment";
import Button from "@mui/material/Button";
import ReactExport from "react-export-excel";
import ExportIcon from "&assets/icons/export";
import { colorPalette } from "&config/colors";
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
const ExcelFile = ReactExport.ExcelFile;

export default function ExportButton(props) {
  const renderContents = () => {
    if (props.name === "File Record") {
      return (
        <ExcelSheet data={props.data} name={props.name}>
          <ExcelColumn label="File Name" value="fileName" />
          <ExcelColumn label="Record Updated" value="updatedRecord" />
          <ExcelColumn label="No. Of Transactions" value="noOfTransactions" />
          <ExcelColumn
            label="Uploaded Date"
            value={(col) => moment(col.createdDate).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date From"
            value={(col) => moment(col.dateFrom).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date To"
            value={(col) => moment(col.dateTo).format("DD/MM/YYYY")}
          />
        </ExcelSheet>
      );
    } else {
      return (
        <ExcelSheet data={props.data} name={props.name}>
          <ExcelColumn label="Loan ID" value="loanId" />
          <ExcelColumn label="Merchant" value="merchantName" />
          <ExcelColumn label="Order ID" value="merchantOrderId" />
          <ExcelColumn label="Amount" value="amount" />
          <ExcelColumn label="Name" value="certName" />
          <ExcelColumn label="Payout Type" value="payoutType" />
          <ExcelColumn label="Bank Name" value="bankName" />
          <ExcelColumn label="Bank Code" value="bankCode" />
          <ExcelColumn label="Account Number" value="bankNo" />
          <ExcelColumn label="MSISDN" value="msisdn" />
          <ExcelColumn label="Status" value="status" />
          <ExcelColumn label="Ref Number" value="transactionReference" />
          <ExcelColumn
            label="Created Date"
            value={(col) => moment(col.createdDate).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Created Time"
            value={(col) => moment(col.createdDate).format("HH:mm:ss")}
          />
          <ExcelColumn
            label="Date From"
            value={(col) => moment(col.dateFrom).format("DD/MM/YYYY")}
          />
          <ExcelColumn
            label="Date To"
            value={(col) => moment(col.dateTo).format("DD/MM/YYYY")}
          />
        </ExcelSheet>
      );
    }
  };
  return (
    <ExcelFile
      element={
        <Button
          sx={{
            border: `1px solid ${colorPalette.primary}`,
            backgroundColor: colorPalette.primary,
            display: "flex",
            gap: 1,
            alignItems: "center",
            borderRadius: "5px",
            padding: "0.5rem 1rem",
            color: "#ffffff",
            "&:hover": {
              backgroundColor: colorPalette.primaryLight,
              color: colorPalette.primary,
            },
          }}
        >
          <ExportIcon />
          Export
        </Button>
      }
      filename={`Loan_Disbursements_${moment().format("DD-MM-YYYY")}`}
    >
      {renderContents()}
    </ExcelFile>
  );
}
