import { colorPalette } from "&config/colors";
import * as React from "react";

export default function UploadIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.02"
      height="18.5"
      viewBox="0 0 18.02 18.5"
    >
      <g
        id="Iconly_Light_Upload"
        data-name="Iconly/Light/Upload"
        transform="translate(0.75 0.75)"
      >
        <g id="Upload" transform="translate(0 17) rotate(-90)">
          <path
            id="Stroke_1"
            data-name="Stroke 1"
            d="M10.934,4.124V3.291A3.291,3.291,0,0,0,7.643,0H3.29A3.291,3.291,0,0,0,0,3.291V13.23A3.291,3.291,0,0,0,3.29,16.52H7.652a3.282,3.282,0,0,0,3.282-3.281V12.4"
            transform="translate(0 0)"
            fill="none"
            stroke={colorPalette.primary}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_3"
            data-name="Stroke 3"
            d="M10.753,0H0"
            transform="translate(6.248 8.26)"
            fill="none"
            stroke={colorPalette.primary}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
          <path
            id="Stroke_5"
            data-name="Stroke 5"
            d="M0,0,2.615,2.6,0,5.207"
            transform="translate(14.385 5.657)"
            fill="none"
            stroke={colorPalette.primary}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
}
