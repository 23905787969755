import * as React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import DownCarret from "&assets/icons/down-carret";
import { colorPalette } from "&config/colors";

const useStyles: Function = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: colorPalette.alto,
    },
    "& .MuiInputLabel-outlined.Mui-focused": {
      color: colorPalette.mine_shaftapprox,
    },
  },
});

interface Props {
  value: string;
  onSelect: Function;
  menuItems: Array<any>;
  hasError?: boolean;
  errorMessage?: string;
  placeHolder?: string;
  onClick?: Function;
  size?: "medium" | "small";
  Icon?: any;
}

export default function SelectComponent(props: Props) {
  /**
   *   menu items in the following format
   * [{label:"", value:""}]
   */
  const {
    value,
    onSelect,
    menuItems,
    hasError = false,
    errorMessage = "",
    placeHolder = "",
    size = "medium",
    onClick,
    Icon,
  } = props;
  const classes = useStyles();

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth size={size} className={classes.root}>
        {!isEmpty(placeHolder) && (
          <InputLabel
            htmlFor="outlined-adornment-email"
            required
            error={hasError}
          >
            {placeHolder}
          </InputLabel>
        )}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={!isEmpty(placeHolder) ? placeHolder : undefined}
          onChange={(e) => onSelect(e)}
          onClick={(e) => onClick && onClick(e)}
          error={hasError}
          sx={{
            backgroundColor: "#fff",
            font: "normal normal normal 14px/18px Outfit",
          }}
          IconComponent={() => (Icon ? <Icon /> : <DownCarret />)}
        >
          {menuItems.length > 0 &&
            menuItems.map((menu, index) => (
              <MenuItem value={menu.value} key={index}>
                {menu.label}
              </MenuItem>
            ))}
        </Select>
        <FormHelperText
          style={{
            display: hasError ? "block" : "none",
            color: "red",
            fontSize: 10,
          }}
          id={`component-error-email`}
        >
          {errorMessage}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
