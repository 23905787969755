import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { DataGrid, GridColDef,GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header/pageHeader";
import { RootState } from "&store/store";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { getCurrentUser } from "&config/getCurrentUser";
import { checkoutLogsActions } from "./checkoutLogs.slice";
import { OutlinedButton, SubmitButton } from "&styled/button/button.component";
import { colorPalette } from "&config/colors";

type ReduxProps = ConnectedProps<typeof connector>;

const CheckoutLogsComponent = (props: ReduxProps) => {
  const {token, state, getCheckoutLogs, searchCheckoutLogs} = props;
  const { data } = state;
  const user: any = getCurrentUser(token);
  const { userTypeId } = user;
  const [search, setSearch] = useState({
    from: new Date(),
    to: new Date(),
  });
  const [pageSize, setPageSize] = React.useState(25);

  useEffect(() => {
    (async () => {
      await getCheckoutLogs();
    })();
  }, [getCheckoutLogs]);


  const handleDateChange = (val, type) => {
    setSearch((prevState) => ({ ...prevState, [type]: val }));
  };

  const onSearch = async () => {
    try {
      await searchCheckoutLogs(search);
    } catch (e) {
      console.log(e);
    }
  };
  const onReset = async () => {
    setSearch({ from: new Date(), to: new Date() });
    await getCheckoutLogs();
  };
  
  const renderStatus = (row) => {
    if(row.provider === "jazzcash"){
      return row.response.pp_ResponseMessage
    }else{
      return row.response.responseDesc
    }
  }
  const renderOrderId = (row) => {
    if(row.provider === "jazzcash"){
      return row.response.pp_TxnRefNo
    }else{
      return row.request.orderId
    }
  }
  const renderAmount = (row) => {
    if(row.provider === "jazzcash"){
      return row.response.pp_Amount
    }else{
      return row.request.transactionAmount
    }
  }
  const renderMsisdn = (row) => {
    if(row.provider === "jazzcash"){
      return row.response.pp_MobileNumber
    }else{
      return row.request.mobileAccountNo
    }
  }

  const columns: GridColDef[] = [
    {
      field: "provider",
      headerName: "Operator",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "orderId",
      headerName: "Order ID",
      renderCell: ({row})=> renderOrderId(row),
      width: 250,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionAmount",
      headerName: "Amount",
      renderCell: ({row})=> renderAmount(row),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      width: 150,
      renderCell: ({ row }) => renderMsisdn(row),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "createdDate",
      headerName: "Request Date",
      renderCell: ({ row }) =>
        moment(row.createdDate).format("DD MMM YYYY HH:mm A"),
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "status",
      headerName: "STATUS",
      renderCell: ({ row }) => renderStatus(row),
      flex: 1,
      headerAlign: "left",
      align: "left",
    },
    
  ];


  return (
    <>
    <PageHeader title="Checkout Logs" />
    <Box
      sx={{
        marginY: "1rem",
        borderTop: `4px solid ${colorPalette.primary}`,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
      }}
    >
      <Card>
        <CardContent>
          {/*Search filters*/}
          <Box sx={{ marginY: "1rem" }}>
            <Grid container spacing={2}>
             
              <Grid item lg={2} marginTop={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={search.from}
                    onChange={(val) => handleDateChange(val, "from")}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        placeholder="From"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item lg={2} marginTop={1}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={search.to}
                    onChange={(val) => handleDateChange(val, "to")}
                    renderInput={(params) => (
                      <TextField {...params} size="small" placeholder="To" />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item lg={1} marginTop={1}>
                <SubmitButton title="Search" handlePress={onSearch} />
              </Grid>
              <Grid item lg={1} marginTop={1}>
                <OutlinedButton title="Reset" handlePress={onReset} />
              </Grid>
              
            </Grid>
          </Box>
          <div style={{ height: 650, width: "100%" }}>
            <DataGrid
              rows={data}
              columns={columns}
              pageSize={pageSize}
              rowsPerPageOptions={[25, 50, 100]}
              getRowId={(row) => row._id}
              components={{
                Toolbar: GridToolbar,
              }}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              disableSelectionOnClick
            />
          </div>
        </CardContent>
      </Card>
    </Box>
   
  </>
  )
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  token: state.login.token,
  state: state.checkoutLogs,
});


const mapDispatchToProps = {
  getCheckoutLogs: checkoutLogsActions.getCheckoutLogs,
  searchCheckoutLogs: checkoutLogsActions.searchCheckoutLogs,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const CheckoutLogsComponentRedux = connector(CheckoutLogsComponent);

export { CheckoutLogsComponentRedux as CheckoutLogsComponent };
