import * as React from "react";

export default function MerchantsBigIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g id="Group_5668" data-name="Group 5668" transform="translate(-823 -96)">
        <circle
          id="Ellipse_1753"
          data-name="Ellipse 1753"
          cx="30.5"
          cy="30.5"
          r="30.5"
          transform="translate(823 96)"
          fill="#EBF7DF"
        />
        <g
          id="Iconly_Light-Outline_3_User"
          data-name="Iconly/Light-Outline/3 User"
          transform="translate(838.795 114.868)"
        >
          <g id="_3_User" data-name="3 User">
            <path
              id="Combined_Shape"
              data-name="Combined Shape"
              d="M5.831,19.541c0-4.574,7.047-4.574,9.364-4.574s9.364,0,9.364,4.6c0,4.569-6.8,4.569-9.364,4.569C12.878,24.133,5.831,24.133,5.831,19.541Zm2.094,0c0,2.013,3.953,2.436,7.27,2.436s7.27-.418,7.27-2.412c0-2.018-3.953-2.441-7.27-2.441S7.925,17.542,7.925,19.541Zm18.818.649a1.087,1.087,0,0,1,.605-1.39c.947-.371.947-.781.947-.978,0-.63-.778-1.07-2.31-1.305a1.075,1.075,0,0,1-.881-1.228,1.058,1.058,0,0,1,1.192-.905c3.382.523,4.091,2.158,4.091,3.438a3.147,3.147,0,0,1-2.294,2.992,1.026,1.026,0,0,1-.373.071A1.05,1.05,0,0,1,26.743,20.19ZM2.3,20.813A3.151,3.151,0,0,1,0,17.822c0-1.279.711-2.916,4.094-3.438a1.059,1.059,0,0,1,1.191.905A1.077,1.077,0,0,1,4.4,16.517c-1.533.235-2.31.675-2.31,1.305,0,.2,0,.605.947.978a1.086,1.086,0,0,1,.605,1.39,1.048,1.048,0,0,1-.979.695A1.025,1.025,0,0,1,2.3,20.813Zm12.862-7.681A6.217,6.217,0,0,1,10.665,11.2,6.589,6.589,0,0,1,8.82,6.562,6.479,6.479,0,0,1,15.2,0a6.481,6.481,0,0,1,6.376,6.567A6.48,6.48,0,0,1,15.2,13.133ZM10.914,6.567A4.423,4.423,0,0,0,12.15,9.679a4.18,4.18,0,0,0,3.011,1.3l.035,1.077V10.978a4.2,4.2,0,0,0,3.029-1.295A4.446,4.446,0,0,0,19.48,6.567a4.285,4.285,0,1,0-8.566,0Zm11.62,4.213a1.073,1.073,0,0,1,.892-1.217,3.063,3.063,0,0,0,.068-6.028A1.076,1.076,0,0,1,22.63,2.3a1.053,1.053,0,0,1,1.2-.889,5.149,5.149,0,0,1,4.236,5.14A5.187,5.187,0,0,1,23.714,11.7a1.085,1.085,0,0,1-.144.01A1.059,1.059,0,0,1,22.533,10.78ZM6.674,11.7A5.187,5.187,0,0,1,2.32,6.55,5.149,5.149,0,0,1,6.557,1.407a1.045,1.045,0,0,1,1.2.889A1.076,1.076,0,0,1,6.9,3.535a3.063,3.063,0,0,0,.065,6.028,1.072,1.072,0,0,1,.892,1.217,1.059,1.059,0,0,1-1.036.929A1.116,1.116,0,0,1,6.674,11.7Z"
              fill="#111"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
