import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import TrashIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import CardContent from "@mui/material/CardContent";
import { useHistory } from "react-router-dom";
import PageHeader from "&styled/page-header";
import FabButton from "&styled/FabButton";
import { RootState } from "&store/store";

import { subscriptionsActions } from "./subscriptions.slice";

import styles from "./subscriptions.module.css";
import { colorPalette } from "&config/colors";

type ReduxProps = ConnectedProps<typeof connector>;

const SubscriptionsComponent = (props: ReduxProps) => {
  const history = useHistory();
  const { state, getSubscriptions } = props;
  const { subscriptions } = state;
  const { data, current, pages, total } = subscriptions;
  const [pageSize, setPageSize] = React.useState(25);
  const [rowsState, setRowsState] = React.useState({
    page: 0,
    pageSize: 5,
    rows: [],
    loading: false,
  });

  const columns: GridColDef[] = [
    {
      field: "country",
      headerName: "Country",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "operator",
      headerName: "Operator",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "merchantName",
      headerName: "Merchant",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "serviceName",
      headerName: "Service",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "msisdn",
      headerName: "MSISDN",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "subscriptionType",
      headerName: "Subscription Type",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "subscriptionAmount",
      headerName: "Subscription Amount",
      width: 150,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "subscriberStatus",
      headerName: "Status",
      width: 150,
      headerAlign: "left",
      align: "left",
    },

    {
      field: "subscriptionDate",
      headerName: "Subscription Date",
      width: 150,
      renderCell: ({ row }) => moment(row.createdDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
  ];

  useEffect(() => {
    (async () => {
      await getSubscriptions();
    })();
  }, [getSubscriptions]);

  return (
    <>
      <PageHeader title="Subscriptions" />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                paginationMode="server"
                onPageChange={(page) =>
                  setRowsState((prev) => ({ ...prev, page }))
                }
                onPageSizeChange={(pageSize) =>
                  setRowsState((prev) => ({ ...prev, pageSize }))
                }
                rows={data}
                columns={columns}
                pageSize={pageSize}
                page={current}
                rowCount={total}
                getRowId={(row) => row._id}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState) => ({
  state: state.subscriptions,
});

const mapDispatchToProps = {
  getSubscriptions: subscriptionsActions.getSubscriptions,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
const SubscriptionsComponentRedux = connector(SubscriptionsComponent);

export { SubscriptionsComponentRedux as SubscriptionsComponent };
