import React, { useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import FabButton from "&styled/FabButton";
import RemoveRedEyeRoundedIcon from "@mui/icons-material/RemoveRedEyeRounded";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import PageHeader from "&styled/page-header";
import {
  DataGrid,
  GridColDef,
  GridToolbar,
  GridCellParams,
} from "@mui/x-data-grid";
import { formatter } from "&config/currencyFormatter";
import { colorPalette } from "&config/colors";

export default function SettlementReport({ onSearch, data = [] }) {
  const [pageSize, setPageSize] = React.useState(25);
  useEffect(() => {
    (async () => {
      const vals = {
        dateFrom: new Date(),
        dateTo: new Date(),
        country: "Pakistan",
        currency: "PKR",
      };
      await onSearch(vals);
    })();
  }, [onSearch]);

  const renderBtnShow = (row) => {
    return (
      <FabButton label="Show" href={`/settlement-summary/${row._id}`}>
        <RemoveRedEyeRoundedIcon />
      </FabButton>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "transactionId",
      headerName: "ID",
      width: 150,
      renderCell: ({ row }) => (
        <Box sx={{ color: "#6631F7", cursor: "pointer" }}>
          {Math.round(row.transactionId)}
        </Box>
      ),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "transactionDate",
      headerName: "Transaction Date",
      width: 200,
      renderCell: ({ row }) =>
        moment(row.transactionDate).format("DD MMM YYYY"),
      headerAlign: "left",
      align: "left",
    },
    {
      field: "channel",
      headerName: "Channel",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "settledCurrency",
      headerName: "Currency",
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "grossSettledAmount",
      headerName: "Amount",
      renderCell: ({ row }) =>
        formatter("US", "USD").format(row.grossSettledAmount),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "netSettledAmount",
      headerName: "Net Settled Amount",
      renderCell: ({ row }) =>
        formatter("US", "USD").format(row.netSettledAmount),
      width: 200,
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      headerName: "",
      renderCell: ({ row }) => renderBtnShow(row),
      flex: 1,
      headerAlign: "right",
      align: "center",
    },
  ];
  return (
    <>
      <PageHeader
        title="Settlement Report"
        onSearch={onSearch}
        type="settlement"
      />
      <Box
        sx={{
          marginY: "1rem",
          borderTop: `4px solid ${colorPalette.primary}`,
          borderTopLeftRadius: "4px",
          borderTopRightRadius: "4px",
          "& .purple": {
            backgroundColor: colorPalette.primaryLight,
            color: "#3C3C3C",
          },
        }}
      >
        <Card>
          <CardContent>
            <div style={{ height: 700, width: "100%" }}>
              <DataGrid
                rows={data}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[25, 50, 100, 500]}
                getRowId={(row) => row._id}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                disableSelectionOnClick
                components={{
                  Toolbar: GridToolbar,
                }}
                getCellClassName={(params: GridCellParams<number>) => {
                  if (
                    params.field === "carrierShareValue" ||
                    params.field === "merchantShareValue" ||
                    params.field === "waleeShareValue"
                  ) {
                    return "purple";
                  } else {
                    return "";
                  }
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
