import * as React from "react";

export default function CarriersBigIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="61"
      height="61"
      viewBox="0 0 61 61"
    >
      <g id="Group_5666" data-name="Group 5666" transform="translate(-265 -96)">
        <circle
          id="Ellipse_1751"
          data-name="Ellipse 1751"
          cx="30.5"
          cy="30.5"
          r="30.5"
          transform="translate(265 96)"
          fill="#EBF7DF"
        />
        <g
          id="Iconly_Light-Outline_Swap"
          data-name="Iconly/Light-Outline/Swap"
          transform="translate(306.916 114.208) rotate(90)"
        >
          <g id="Swap" transform="translate(0 0)">
            <path
              id="Combined_Shape"
              data-name="Combined Shape"
              d="M17.967,22.646h0l0,0h0a.951.951,0,0,1-.1-.09l0,0L12.737,17.3a.973.973,0,0,1,0-1.357.928.928,0,0,1,1.226-.09l.105.093,3.51,3.6V4.435a.941.941,0,0,1,1.874-.13l.009.13V19.545l3.51-3.595a.927.927,0,0,1,1.331,0A.975.975,0,0,1,24.4,17.2l-.092.107L19.19,22.549h0l-.005.005a.925.925,0,0,1-1.218.091ZM5.127,18.527l-.008-.13V3.285l-3.51,3.6a.929.929,0,0,1-1.226.1L.277,6.886a.976.976,0,0,1-.094-1.25l.092-.108L5.394.282A.934.934,0,0,1,5.78.043h0L5.8.037l.006,0,.015,0,.008,0,.013,0,.011,0,.011,0,.013,0,.009,0,.014,0,.009,0,.014,0h.009l.014,0H6.15l.019,0h0l.02,0H6.2l.013,0,.009,0,.011,0,.008,0,.012,0,.008,0,.013,0,.007,0a.929.929,0,0,1,.343.163l0,0A.952.952,0,0,1,6.72.275l.008.007,5.119,5.245a.976.976,0,0,1,0,1.359.928.928,0,0,1-1.226.09l-.106-.093L7,3.285V18.4a.941.941,0,0,1-1.874.13Z"
              fill="#111"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}
