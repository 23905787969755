import React, { useState, useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";
import moment from "moment";
import { RootState } from "&store/store";
import { reportsActions } from "../reports.slice";
import WaleeLogo from "&assets/images/walee-logo.png";
import PageHeader from "&styled/page-header";
import { ReactComponent as WaleeMono } from "&assets/images/walee-mono.svg";
import { formatter } from "&config/currencyFormatter";
import { colorPalette } from "&config/colors";

type ReduxProps = ConnectedProps<typeof connector>;

const SettlementDetailViewComponent = (props: ReduxProps) => {
  const { settlementId, getSettlementSummary } = props;

  const handlePrint = (divName) => {
    var printContents = document.getElementById(divName)!.innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  };

  const [data, setData] = useState({
    _id: "",
    country: "",
    merchantName: "",
    totalRevenue: "",
    channel: "",
    waleeShare: "",
    waleeSharePercentage: "",
    merchantShare: "",
    merchantSharePercentage: "",
    subTotal: "",
    remittanceCharges: "",
    remittanceChargesPercentage: "",
    netTransferAmount: "",
    exchangeRate: "",
    netSettledAmount: 0,
    localCurrency: "",
    settledCurrency: "",
    userTypeId: "",
    grossSettledAmount: "",
    transactionId: 0,
    transactionDate: "",
  });
  useEffect(() => {
    (async () => {
      const { payload } = await getSettlementSummary(settlementId);
      setData(payload);
    })();
  }, [settlementId, getSettlementSummary]);

  // rendrer
  const renderSubTotal = () => {
    return (
      <>
        <Grid item lg={2} xs={12} marginTop={3}></Grid>
        <Grid item lg={2} xs={12} marginTop={3}></Grid>
        <Grid item lg={2} xs={12} marginTop={3}></Grid>
        <Grid item lg={2} xs={12} marginTop={3}></Grid>
        <Grid item lg={2} xs={12} marginTop={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>Sub Total</Box>
        </Grid>
        <Grid item lg={2} xs={12} marginTop={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            {formatter("PK", "PKR").format(parseInt(data.subTotal))}
          </Box>
        </Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            Remittance Charges -WHT ({data.remittanceChargesPercentage}%)
          </Box>
        </Grid>
        <Grid item lg={2} xs={12} marginBottom={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            {formatter("PK", "PKR").format(parseInt(data.remittanceCharges))}
          </Box>
        </Grid>
      </>
    );
  };

  const renderNetTransfer = () => {
    return (
      <>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            Net Transferred Amount (PKR)
          </Box>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            {formatter("PK", "PKR").format(parseInt(data.netTransferAmount))}
          </Box>
        </Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}></Grid>
        <Grid item lg={2} xs={12} marginBottom={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            Exchange Rate (XE)
          </Box>
        </Grid>
        <Grid item lg={2} xs={12} marginBottom={3}>
          <Box sx={{ fontSize: "12px", fontWeight: "600" }}>
            {data.exchangeRate}
          </Box>
        </Grid>
      </>
    );
  };

  const renderNetSettled = () => {
    return (
      <>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}></Grid>
        <Grid item lg={2} xs={12}>
          <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
            Net Settled Amount (USD)
          </Box>
        </Grid>
        <Grid item lg={2} xs={12}>
          <Box sx={{ fontSize: "16px", fontWeight: "600" }}>
            {formatter("US", "USD").format(data.netSettledAmount)}
          </Box>
        </Grid>
      </>
    );
  };
  return (
    <>
      <PageHeader
        title={`Transaction # ${Math.round(data.transactionId)}`}
        hasPrint={true}
        onPrint={handlePrint}
      />

      <div id="page">
        <Box
          sx={{
            marginY: "1rem",
            borderTop: `4px solid ${colorPalette.primary}`,
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            "& .purple": {
              backgroundColor: colorPalette.primaryLight,
              color: "#3C3C3C",
            },
          }}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginY: "1rem",
                }}
              >
                <img
                  src={WaleeLogo}
                  alt="Walee Logo"
                  style={{ width: "185px", height: "60px" }}
                />
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ color: "#3C3C3C", fontSize: "12px" }}>
                    Currency
                  </Box>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "16px",
                      fontWeight: "600",
                    }}
                  >
                    {data.localCurrency}
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  marginY: "2rem",
                  backgroundColor: "#F6F2FF",
                  padding: "2rem",
                  borderRadius: "11px",
                }}
              >
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ fontSize: "12px" }}>Transaction ID</Box>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#6631F7",
                      }}
                    >
                      {Math.round(data.transactionId)}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "right",
                    }}
                  >
                    <Box sx={{ fontSize: "12px" }}>Transaction Date</Box>
                    <Box
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        color: "#6631F7",
                      }}
                    >
                      {moment(data.transactionDate).format("dddd, DD MMM YYYY")}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Grid container spacing={2} marginX={3} marginY={2}>
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Channel
                  </Box>
                </Grid>
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Country
                  </Box>
                </Grid>
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Total Collected Revenue
                  </Box>
                </Grid>
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    WFS Share ({data.waleeSharePercentage}%)
                  </Box>
                </Grid>
                <Grid item lg={2} xs={12}>
                  <Box
                    sx={{
                      color: "#3C3C3C",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Merchant Share ({data.merchantSharePercentage}%)
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: "100%",
                  border: "1px solid #E8E8E8",
                  marginY: "1rem",
                }}
              ></Box>
              <Grid container spacing={2} marginX={3} sx={{ fontSize: "12px" }}>
                <Grid item lg={2} xs={12}>
                  {data.channel}
                </Grid>
                <Grid item lg={2} xs={12}>
                  {data.country}
                </Grid>
                <Grid item lg={2} xs={12}>
                  {formatter("PK", "PKR").format(parseInt(data.totalRevenue))}
                </Grid>
                <Grid item lg={2} xs={12}>
                  {formatter("PK", "PKR").format(parseInt(data.waleeShare))}
                </Grid>
                <Grid item lg={2} xs={12}>
                  {formatter("PK", "PKR").format(parseInt(data.merchantShare))}
                </Grid>

                {renderSubTotal()}
                {renderNetTransfer()}
                {renderNetSettled()}
              </Grid>
              <Box
                sx={{
                  marginY: "2rem",
                  backgroundColor: "#EEFFE6",
                  padding: "1rem",
                  position: "relative",
                }}
              >
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box>Status</Box>
                  <Box sx={{ fontSize: "16px", fontWeight: "600" }}>Paid</Box>
                </Box>
                <Box
                  sx={{ position: "absolute", right: "1.5rem", top: "-1rem" }}
                >
                  <WaleeMono />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </div>
    </>
  );
};

/**
 * Maps state variables from redux store to props of currect component
 * @param state
 */
const mapStateToProps = (state: RootState, ownProps) => ({
  state: state.reports,
  settlementId: ownProps.match.params.settlementId,
});

/**
 * Maps actions from slices to props
 */
const mapDispatchToProps = {
  getSettlementSummary: reportsActions.getSettlementSummary,
};

/**
 * Connects component to redux store
 */
const connector = connect(mapStateToProps, mapDispatchToProps);
const SettlementDetailViewComponentRedux = connector(
  SettlementDetailViewComponent
);

export { SettlementDetailViewComponentRedux as SettlementDetailViewComponent };
